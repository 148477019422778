<!-- 编辑阶梯折扣 -->
<!-- 开发者：王雪茹 -->
<template>
  <div class="block_box">
    <div class="main">
      <!-- 页头标题 -->
      <div class="top pull-left">
        <div class="left-content pull-left padding10">
          <svg class="icon quotationImg pull-left" aria-hidden="true">
            <use href="#icon-cloudtab11_norm"></use>
          </svg>
          <div>
            <!-- 编辑折扣 -->
            <p class="quotationCode">{{ $t("label.edit.discount") }}</p>
            <p class="title">{{ schedulename }}</p>
          </div>
        </div>
      </div>
      <div class="edit_box">
        <!-- 表格部分 -->
        <el-form
          :model="blockForm"
          ref="blockForm"
          class="book_form demo-ruleForm mar-t-20"
        >
          <!-- 编辑折扣 -->
          <h4>{{ $t("label.edit.discount") }}</h4>
          <el-table
            :data="blockForm.etyyStr"
            style="width: 100%"
            :header-cell-style="{
              background: '#F5F5F5',
              color: '#3E3E3C',
              fontWeight: 400,
            }"
            class="book_table"
            :height="bookHeight"
            border
            v-loading="loading"
          >
            <el-table-column type="index" width="78"></el-table-column>
            <template v-for="item in tableAttr">
              <el-table-column :label="item.label" :key="item.prop">
                <template slot-scope="scope">
                  <el-form-item
                    :prop="'etyyStr.' + scope.$index + `.${item.apiname}`"
                  >
                    <!-- 普通类型字段 -->
                    <el-input
                      v-if="item.fieldtype === 'S'"
                      v-model="scope.row[item.apiname]"
                      size="small"
                      :maxlength="
                        item.schemefieldLength === null ||
                        item.schemefieldLength === undefined
                          ? 1000
                          : Number(item.schemefieldLength)
                      "
                      auto-complete="off"
                    ></el-input>
                    <!-- 数字类型字段 -->
                    <el-input-number
                      v-if="
                        item.fieldtype === 'N' ||
                        item.fieldtype === 'c' ||
                        item.fieldtype === 'P'
                      "
                      v-model="scope.row[item.apiname]"
                      :min="
                        item.schemefieldLength !== undefined &&
                        item.schemefieldLength !== null
                          ? 0 - Math.pow(10, Number(item.schemefieldLength)) + 1
                          : min
                      "
                      :max="
                        item.schemefieldLength !== undefined &&
                        item.schemefieldLength !== null
                          ? Math.pow(10, Number(item.schemefieldLength)) - 1
                          : max
                      "
                      :precision="
                        item.decimalPlaces !== undefined &&
                        item.decimalPlaces !== null
                          ? Number(item.decimalPlaces)
                          : precision
                      "
                      :controls="false"
                    ></el-input-number>
                  </el-form-item>
                </template>
              </el-table-column>
            </template>
            <!-- 操作 -->
            <el-table-column label="" width="108">
              <template slot-scope="scope">
                <div class="btn_all">
                  <el-button
                    @click="delShopList(scope.$index, scope.row)"
                    size="mini"
                    class="btn_s"
                    >-</el-button
                  >
                  <el-button
                    @click="addShopList(scope.row)"
                    size="mini"
                    v-if="blockForm.etyyStr.length - 1 === scope.$index"
                    class="btn_s"
                    >+</el-button
                  >
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-form>
        <!-- 底部按钮 -->
        <div class="dialog-footer">
          <el-button @click="blockClose" size="small">
            <!-- 取消 -->
            {{ $t("label.ems.cancel") }}
          </el-button>
          <el-button type="primary" @click="blockSubmit" size="small">
            <!-- 保存 -->
            {{ $t("component.telerecord.button.save") }}</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as quotationApi from "./api.js";

export default {
  name: "ladderDiscount",
  components: {},
  data() {
    return {
      bookHeight: "300px",
      // pricebooks: [],
      tableAttr: [],
      blockForm: {
        etyyStr: [
          {
            name: "",
            lowerbound: "",
            upperbound: "",
            discountamount: "",
            currency: "",
            discount: "",
          },
        ],
      },
      initData: {
        name: "",
        lowerbound: "",
        upperbound: "",
        discountamount: "",
        currency: "",
        discount: "",
      },
      productid: this.$route.query.id, //折扣计划id
      // isSaveflag: false,
      schedulename: "",
      loading: false,
      isDele: false, //判断是否是操作了删除减号按钮
      min: 0 - Math.pow(10, 18) + 1,
      max: Math.pow(10, 18) - 1,
      precision: 2,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.bookHeight =
        document.getElementsByClassName("block_box")[0].clientHeight -
        189 +
        "px"; //减去头部高度
    });
  },
  created() {
    this.showDiscountSchedule();
  },
  methods: {
    //编辑折扣列表
    showDiscountSchedule() {
      this.loading = true;
      let data = {
        discountscheduleid: this.productid,
      };
      //展示编辑折扣
      quotationApi.showDiscountSchedule(data).then((res) => {
        if (res.data) {
          //表头数据的处理
          this.tableAttr = res.data.tableLabel;
          if (res.data.schedulelist.length > 0) {
            res.data.schedulelist.forEach((val) => {
              if (val.upperbound === null) {
                val.upperbound = undefined;
              }
              if (val.lowerbound === null) {
                val.lowerbound = undefined;
              }
            });
            this.blockForm.etyyStr = res.data.schedulelist;
          } else {
            //判断是否是操作了删除减号按钮
            if (!this.isDele) {
              this.blockForm.etyyStr = [
                {
                  id: "",
                  name: "",
                  lowerbound: "",
                  upperbound: "",
                  currency: "",
                  discount: "",
                  discountamount: "",
                },
              ];
            } else {
              this.blockForm.etyyStr = [];
            }
          }
          this.schedulename = res.data.schedulename;
          this.loading = false;
        }
      });
    },
    //加
    addShopList(row) {
      this.initData.xj = row.sj;
      this.blockForm.etyyStr.push({
        ...this.initData,
        id: "",
      });
    },
    //减
    delShopList(id, row) {
      if (row.id) {
        quotationApi
          .deleteData({ id: row.id, type: "discounttiers" })
          .then((res) => {
            if (res.result) {
              this.$message({
                showClose: true,
                message: this.$i18n.t("label.ems.delete.success"),
                type: "success",
              }); //删除成功
              this.isDele = true;
              this.showDiscountSchedule();
            }
          });
      } else {
        this.isDele = false;
        this.blockForm.etyyStr.splice(id, 1);
      }
    },
    //取消
    blockClose() {
      this.$router.go(-1);
    },
    //快速保存、保存
    blockSubmit() {
      //上界必须大于下届
      //每行下界必须等于上一行上届
      //最后一行和第一行上界可以为空
      let judgeFlag = true;
      let isSave = false;
      let etyyStrLength = this.blockForm.etyyStr.length;
      this.blockForm.etyyStr.forEach((val, index) => {
        this.blockForm.etyyStr[index].isSaveflag = false;
        //每行名称为必填项
        if (val.name !== undefined && val.name !== null && val.name !== "") {
          //每行下界为必填项
          if (val.lowerbound !== undefined) {
            //上界必须大于下届（除第一行和最后一行外）
            if (
              val.lowerbound < val.upperbound &&
              index !== 0 &&
              index !== etyyStrLength - 1
            ) {
              //每行下界必须等于上一行上届
              if (
                this.blockForm.etyyStr[index].lowerbound ===
                this.blockForm.etyyStr[index - 1].upperbound
              ) {
                //如果为true表示校验完成，可保存
                this.blockForm.etyyStr[index].isSaveflag = true;
                this.blockForm.etyyStr[0].isSaveflag = true;
              } else {
                this.blockForm.etyyStr[index].isSaveflag = false;
                //'编辑折扣每1层的下界必须等于上1层的上界。'
                this.$message.error(
                  this.$i18n.t(
                    "label.the.lower.bound.of.each.layer.of.editing.discount.must.be.equal.to.the.upper.bound.of.the.upper.level."
                  )
                );
                judgeFlag = false;
              }
            } else if (index === etyyStrLength - 1 && index !== 0) {
              // 最后一行的判断
              if (
                this.blockForm.etyyStr[index].lowerbound ===
                this.blockForm.etyyStr[index - 1].upperbound
              ) {
                //最后一行为空或是比下界大，可保存
                if (
                  this.blockForm.etyyStr[index].upperbound === undefined ||
                  this.blockForm.etyyStr[index].lowerbound <
                    this.blockForm.etyyStr[index].upperbound
                ) {
                  //如果为true表示校验完成，可保存
                  this.blockForm.etyyStr[index].isSaveflag = true;
                  //判断上界是否有值
                  if (this.blockForm.etyyStr[index].upperbound === undefined) {
                    this.blockForm.etyyStr[
                      this.blockForm.etyyStr.length - 1
                    ].upperbound = undefined;
                  } else {
                    this.blockForm.etyyStr[
                      this.blockForm.etyyStr.length - 1
                    ].upperbound =
                      this.blockForm.etyyStr[
                        this.blockForm.etyyStr.length - 1
                      ].upperbound;
                  }
                } else {
                  this.blockForm.etyyStr[index].isSaveflag = false;
                  //编辑折扣每1层的上界必须大于下界。
                  this.$message.error(
                    this.$i18n.t(
                      "label.the.upper.bound.of.each.level.of.edit.discount.must.be.greater.than.the.lower.bound."
                    )
                  );
                  judgeFlag = false;
                }
              } else {
                this.blockForm.etyyStr[index].isSaveflag = false;
                //'编辑折扣每1层的下界必须等于上1层的上界。'
                this.$message.error(
                  this.$i18n.t(
                    "label.the.lower.bound.of.each.layer.of.editing.discount.must.be.equal.to.the.upper.bound.of.the.upper.level."
                  )
                );
                judgeFlag = false;
              }
            } else if (index === 0) {
              // 第一行的判断
              //第一行为空或是比下界大，可保存
              if (
                this.blockForm.etyyStr[index].upperbound === undefined ||
                this.blockForm.etyyStr[index].lowerbound <
                  this.blockForm.etyyStr[index].upperbound
              ) {
                //如果为true表示校验完成，可保存
                this.blockForm.etyyStr[index].isSaveflag = true;
                //判断上界是否有值
                if (this.blockForm.etyyStr[index].upperbound === undefined) {
                  this.blockForm.etyyStr[index].upperbound = undefined;
                }
              } else {
                this.blockForm.etyyStr[index].isSaveflag = false;
                //编辑折扣每1层的上界必须大于下界。
                this.$message.error(
                  this.$i18n.t(
                    "label.the.upper.bound.of.each.level.of.edit.discount.must.be.greater.than.the.lower.bound."
                  )
                );
                judgeFlag = false;
              }
            } else {
              //编辑折扣每1层的上界必须大于下界。
              this.$message.error(
                this.$i18n.t(
                  "label.the.upper.bound.of.each.level.of.edit.discount.must.be.greater.than.the.lower.bound."
                )
              );
              judgeFlag = false;
            }
          } else {
            //编辑折扣每1层的下界为必填项。
            this.$message.error(
              this.$i18n.t(
                "label.edit.lower.bound.of.discount.per.tier.is.required."
              )
            );
            judgeFlag = false;
          }
        } else {
          //名称为必填项。label.name.required
          this.$message.error(this.$i18n.t("label.name.required"));
          judgeFlag = false;
        }
      });
      if (judgeFlag) {
        isSave = this.blockForm.etyyStr.every((val) => {
          return val.isSaveflag;
        });
        if (isSave) {
          let copyData = JSON.parse(JSON.stringify(this.blockForm.etyyStr));
          copyData.forEach((val) => {
            val.lowerbound = val.lowerbound.toString();
            val.upperbound = val.upperbound ? val.upperbound.toString() : "";
            val.discountamount = val.discountamount
              ? val.discountamount.toString()
              : "";
            val.discount = val.discount ? val.discount.toString() : "";
            delete val.isSaveflag;
          });
          quotationApi
            .saveDiscountSchedule({
              discountscheduleid: this.productid,
              tierslist: copyData,
            })
            .then((res) => {
              if (res.result) {
                this.$router.go(-1);
              }
            });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
p {
  margin-bottom: 0;
}
.padding10 {
  padding: 10px;
}
.block_box {
  border-left: none;
  border-right: none;
  border-radius: 3px;
  position: relative;
  height: 100%;
  padding: 10px 10px 10px 10px;

  .main {
    border-radius: 3px;
    border: 1px solid #dedcda;
    background: #fff;
    height: auto;

    .top {
      width: 100%;
      height: 56px;
      background: #f5f5f5;
      border-bottom: 1px solid #dedcda;

      .left-content {
        width: 30%;

        .quotationImg {
          width: 36px;
          height: 36px;
          margin-right: 10px;
        }

        .quotationCode {
          color: #333;
          font-size: 12px;
          height: 16px;
          line-height: 16px;
        }

        .title {
          color: #080707;
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
    .edit_box {
      width: 100%;
      .book_form {
        margin-top: 50px;
        h4 {
          padding-top: 15px;
          padding-left: 10px;
          box-sizing: border-box;
          margin-bottom: 10px;
          font-size: 14px;
          color: #080707;
        }
        .el-form-item :first-child {
          padding-left: 10px;
          box-sizing: border-box;
          .el-form-item__label {
            padding: 0;
          }
        }
        ::v-deep .el-form-item__content {
          padding-left: 0 !important;
          .el-input-number {
            padding-left: 0 !important;
            .el-input {
              padding-left: 0 !important;
            }
          }
          .el-input {
            padding-left: 0 !important;
          }
        }
        ::v-deep .el-input__inner {
          width: 178px;
          height: 30px;
        }
        ::v-deep .el-form-item {
          margin-bottom: 10px;
        }
      }
      .book_table {
        border-top: 1px solid #dedcda;
        ::v-deep th {
          padding: 8px 0;
          border-bottom: 1px solid #dedcda;
          border-right: 1px solid #dedcda;
        }
        ::v-deep td {
          padding: 0;
          border-bottom: 1px solid #dedcda;
          border-right: 0;
          .el-input--small .el-input__inner {
            width: 80%;
            border: 1px solid #888888;
            height: 26px;
            line-height: 26px;
          }
          .el-form-item,
          .el-form-item--mini.el-form-item,
          .el-form-item--small.el-form-item {
            margin-bottom: 0 !important;
          }
          .el-input-number {
            width: 80%;
            .el-input {
              width: 100%;
              input {
                width: 100%;
                text-align: left;
                height: 26px;
                border: 1px solid #888888;
              }
            }
          }
        }
        ::v-deep td:first-child {
          .cell {
            div {
              margin-top: 12px;
            }
          }
        }
        ::v-deep td:last-child {
          .cell {
            display: flex;
            align-items: center;
            .btn_all {
              display: flex;
              .btn_s {
                width: 20px;
                height: 20px;
                padding: 0;
                border: 1px solid #dddbda;
                ::v-deep span {
                  font-size: 20px;
                  width: 20px;
                  height: 20px;
                  display: inline-block;
                  margin-top: -3px;
                  margin-left: -1px;
                  color: #888888;
                }
              }
            }
          }
        }
      }
      .dialog-footer {
        width: 100%;
        margin: 20px 0;
        text-align: center;
      }
    }
  }
}
</style>
