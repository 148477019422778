/*
 * @Description: 
 * @Author: liutq
 * @Date: 2021-06-26 11:11:27
 * @LastEditTime: 2022-02-21 17:17:56
 * @LastEditors: Please set LastEditors
 * @Reference: 
 */
import axios from '@/config/httpConfig'

// 展示产品列表
export function showProductList(data) {
    return axios.post('/cpq/productconfig/showProductList', data)
}

// 保存产品选项(新增，修改)
export function saveProductConfig(data) {
    return axios.post('/cpq/productconfig/saveProductConfig', data)
}

// 校验报价单价格手册
export function checkPriceBook(data) {
    return axios.post('/cpq/quote/checkPriceBook', data)
}

// 获取价格手册
export function getPriceBook(data) {
    return axios.post('/cpq/quote/getPriceBook', data)
}

// 获取编辑报价行信息
export function getEditQuoteLineInfo(data) {
    return axios.post('/cpq/quote/getEditQuoteLineInfo', data)
}

// 获取报价行组
export function getAllQuoteLineGroupList(data) {
    return axios.post('/cpq/quote/getAllQuoteLineGroupList', data)
}

//查询选中的产品的产品选项信息
export function productionOptionInfo(data) {
    return axios.post('/cpq/productconfig/productionOptionInfo', data)
}

// 保存报价单、报价行组、报价行信息
export function save(data) {
    return axios.post('/cpq/quote/save', data)
}

// 添加报价行组
export function addQuoteLineGroup(data) {
    return axios.post('/cpq/quote/addQuoteLineGroup', data)
}

// 删除报价行组
export function deleteQuoteLineGroup(data) {
    return axios.post('/cpq/quote/deleteQuoteLineGroup', data)
}

// 删除报价行
export function deleteQuoteLine(data) {
    return axios.post('/cpq/quote/deleteQuoteLine', data)
}


// 保存复制报价行组
export function saveCopyQuoteLineGroup(data) {
    return axios.post('/cpq/quote/saveCopyQuoteLineGroup', data)
}

// 取消报价行组
export function cancelQuoteLineGroup(data) {
    return axios.post('/cpq/quote/cancelQuoteLineGroup', data)
}

// 报价单重新计算
export function compute(data) {
    return axios.post('/cpq/quote/compute', data)
}

// 展示分块价格
export function showBlockPrice(data) {
    return axios.post('/cpq/productconfig/showBlockPrice', data)
}

// 保存分块报价
export function saveBlockPrice(data) {
    return axios.post('/cpq/productconfig/saveBlockPrice', data)
}

//展示产品配置列表
export function showProductConfigList(data) {
    return axios.post('/cpq/productconfig/showProductConfigList', data)
}

//产品配置页面下展示价格手册产品条目信息
export function showPricebookItems(data) {
    return axios.post('/cpq/productconfig/showPricebookItems', data)
}

// 展示编辑阶梯折扣
export function showDiscountSchedule(data) {
    return axios.post('/cpq/productconfig/showDiscountSchedule', data)
}

// 保存编辑阶梯折扣
export function saveDiscountSchedule(data) {
    return axios.post('/cpq/productconfig/saveDiscountSchedule', data)
}

// 保存编辑阶梯折扣
export function saveProductSetup(data) {
    return axios.post('/cpq/quote/saveProductSetup', data)
}

//删除编辑阶梯折扣或是分块定价
export function deleteData(data) {
    return axios.post('/cpq/productconfig/delete', data)
}

// 复制报价行
export function saveCopyQuoteLine(data) {
    return axios.post('/cpq/quote/saveCopyQuoteLine', data)
}

// 同步报价行至业务机会
export function syncQuoteToOpportunity(data) {
    return axios.post('/cpq/quote/syncQuoteToOpportunity', data)
}

// 展示所选捆绑包的产品选项约束信息
export function optionConstraint(data) {
    return axios.post('/cpq/productconfig/optionConstraint', data)
}
// 获取必选产品选项
export function getRequiredOptions(data) {
    return axios.post('/cpq/quote/getRequiredOptions', data)
}

// 获取选项列表值
export function getSelectValue(data) {
    return axios.post('/view/getSelectValue', data)
}